/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
				$('.bxslider').bxSlider({
					mode: 'fade',
					auto: true
				});
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// Checkout
		'woocommerce_checkout': {
			init: function() {
				// JavaScript to be fired on the checkout page
				$('#customer_details').removeClass('col2-set').addClass('row');
				$('#customer_details .col-1').removeClass('col-1').addClass('col-6').css({"display": "inline-block", "vertical-align": "top"});
				$('#customer_details .col-2').removeClass('col-2').addClass('col-6').css('display','inline-block');
				$('.woocommerce-MyAccount-content .col-1').removeClass('col-1').addClass('col-12');
				$('.woocommerce-MyAccount-content .col-2').removeClass('col-2').addClass('col-12');
			}
		},
		// Edit Address
		'woocommerce_edit_address': {
			init: function() {
				// JavaScript to be fired on the profile page
				$('.woocommerce-Addresses').removeClass('col2-set').addClass('row');
				$('.u-column1').removeClass('col-1').addClass('col-6').css({"display": "inline-block", "vertical-align": "top"});
				$('.u-column2').removeClass('col-2').addClass('col-6').css('display','inline-block');
			}
		},
		// Account
		'woocommerce_account': {
			init: function() {
				// JavaScript to be fired on the account page
				$('#customer_login').removeClass('col2-set').addClass('row');
				$('#customer_login .col-1').removeClass('col-1').addClass('col-6').css({"display": "inline-block", "vertical-align": "top"});
				$('#customer_login .col-2').removeClass('col-2').addClass('col-6').css('display','inline-block');
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
